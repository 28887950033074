@import url("https://fonts.cdnfonts.com/css/akira-expanded");
@import url("https://fonts.cdnfonts.com/css/found-receipt");
:root {
  --red: #f55b1f;
  --white: whitesmoke;
  --green: limegreen;
  --gray: #777777;
}
* {
  padding: 0;
  margin: 0;
  color-scheme: unset;
  box-sizing: border-box;
  font-family: "Akira Expanded", sans-serif;
  list-style: none;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
}
select {
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.formselect {
  width: 90%;
  margin: 5px 0;
}
.ps {
  background: black;
  background-size: cover;
  background-repeat: no-repeat;
}
.cartscreen {
  background:  black;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 600px) {
  .cartscreen {
    background:  black;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.homeh1 {
  padding: 180px 15px 15px;
  color: var(--white);
  text-align: center;
  width: 80%;
  margin: auto;
  line-height: 28px;
}
.homeh1 span {
  color: var(--red);
}
.homeh2 {
  padding: 200px 0 0 0;
  color: var(--white);
  font-size: 35px;
  text-align: center;
  width: 80%;
  margin: auto;
  line-height: 28px;
}
.navbarlogo {
  width: 12%;
  cursor: pointer;
}
.navbarbutton {
  padding: 17px 30px;
  border-radius: 30px;
  border: none;
  font-size: 12px;
  cursor: pointer;
  background-color: var(--white);
  color: black;
}
.sticky {
  background-color: rgba(0, 0, 0, 2);
  width: 100%;
  padding: 30px 95px 5px;
}
nav {
  display: flex;
  justify-content: space-between;
  padding: 30px 95px 5px;
  align-items: center;
  background: transparent;
  margin-bottom: 35px;
  position: fixed;
  z-index: 2;
}
.navs {
  display: flex;
  align-items: center;
  gap: 20px;
}
.homevideo {
  display: flex;
  justify-content: center;
  padding: 50px 0;
  border-radius: 20px;
  margin: auto;
}
.videos {
  width: 70%;
}
@media (max-width: 900px) {
  .videos {
    width: 90%;
  }
  .navbarlogo {
    width: 30%;
  }
  .sticky { 
    padding: 10px;
  }
}
@media (max-width: 768px) {
  nav {
    padding: 30px 50px 5px;
  }
  .videos {
    width: 90%;
  }
  .navbarlogo {
    width: 30%;
  }
  .navbarbutton {
    padding: 14px 30px;
    border-radius: 30px;
    border: none;
    font-size: 9px;
  }
}
@media (max-width: 600px) {
  .homeh1 {
    padding: 150px 0 10px 0;
    font-size: 17px;
    width: 80%;
    line-height: 22px;
    margin: auto;
  }
  nav {
    padding: 20px 20px 0;
  }
  .navbarbutton {
    padding: 12px 20px;
  }
  .navbarlogo {
    width: 30%;
  }
}

.roow {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 170px 0 0 25px;
  width: 100%;
  margin: auto;
}
.col-4 > * {
  flex: 1 1 100%;
}
.col-2 {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.col-22 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0px;
  width: 60%;
  margin-right: 20px;
  gap: 30px;
}
.col-222 {
  display: none;
}
.col-22 img {
  width: 70%;
}
@media (max-width: 1200px) {
  .col-22 img {
    width: 120%;
  }
}
@media (max-width: 1100px) {
  .col-2 {
    display: flex;
    flex-direction: column-reverse;
    width: 250%;
  }
  .col-22 {
    padding-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0;
    gap: 30px;
    width: 100%;
    margin-right: 0;
  }
  .col-22 img {
    width: 25%;
  }
}
@media (max-width: 600px) {
  .col-2 {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
}
.col-4 h4 {
  font-weight: 600;
  font-size: 17px;
  color: var(--white);
  padding-bottom: 5px;
  padding: 20px 0 10px;
}
.col-4 p {
  font-weight: 300;
  font-family: "UCT Found Receipt", sans-serif;
  font-size: 30px;
  color: var(--red);
  padding-bottom: 5px;
}

.col-4 img {
  width: 250px;
  box-sizing: border-box;
  background-color: transparent;
}

.col-4 img:hover {
  transform: scale(1.1);
  transition: 1s;
}

@media (max-width: 1024px) {
  .roow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    align-items: center;
    justify-content: space-around;
  }
  .products {
    width: fit-content;
  }
}

@media (max-width: 550px) {
  .roow {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 150px 0 50px 0;
  }
  .cart {
    width: 30px;
  }
}

.product-row {
  display: flex;
  padding: 50px 100px;
  grid-template-columns: 1fr 1fr;
  gap:250px;
  height: 100%;
}
.large {
  width: 100%;
}

.success {
  color: var(--green);
  font-weight: 600;
  text-transform: uppercase;
}
.danger {
  color: var(--red);
}
.col-11 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-left: 100px; */
}
/* .col-11 > * :nth-child(1) {
  flex: 1 1 0%;
}
.col-11 > *:nth-child(2) {
  flex: 1 1 40%;
} */
.col-1 h1 {
  padding: 0 0 10px 0;
}
.col-1 p {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 15px;
  width: 100%;
  margin-bottom: 5px;
}
.pname {
  font-weight: 500;
  font-size: 25px;
  color: var(--white);
}
.price {
  font-size: 70px;
  margin-bottom: 0px;
  font-family: "UCT Found Receipt", sans-serif;
  color: var(--red);
}
.subtotal {
  color: var(--red);
  text-align: center;
  margin: 25px;
  font-size: 25px;
}
.col-11 p.desc {
  font-size: 12px;
  color: var(--white);
  line-height: 22px;
  list-style: circle;
  list-style-position: outside;
  text-align: left;
  padding: 0px;
}
.col-11 li.desc {
  font-size: 12px;
  color: var(--white);
  line-height: 22px;
  list-style: circle;
  list-style-position: outside;
  text-align: left;
  padding-top: 1px;
}

.col-1 p span {
  font-weight: 300;
  text-transform: lowercase;
}
.col-1 ul li,
.col-3 ul li {
  padding: 0 0 10px 0;
}
.col-3 ul {
  width: 100%;
}
.primary {
  margin: 10px 0 0 0;
  width: 100%;
  background-color: var(--white);
}
.one {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 50px;
}
.one > * {
  flex: 1 1 40%;
}
.qty {
  display: flex;
  position: relative;
}
.drop {
  position: absolute;
  width: 10px;
  top: 20px;
  right: 75px;
}
.drop2 {
  position: absolute;
  width: 10px;
  top: 20px;
  right: 10px;
}
.drop3 {
  position: absolute;
  top: 20px;
  width: 17px;
  right: 45px;
}
.qty select,
select {
  padding: 11.5px 30px 11.5px 10px;
  font-size: 15px;
  background: var(--white);
  outline: none;
}
.productscreen {
  padding: 200px 0 0 0;
}
.linkback {
  padding: 0 0 0 100px;
  color: var(--white);
  font-size: 15px;
  cursor: pointer;
}
.linkback.lone {
  padding-top: 50px;
  padding-bottom: 50px;
}
.linkback.lone.two {
  padding-bottom: 0;
}
.block {
  padding: 13.5px 12.5px;
  margin-top: 15px;
  font-size: 12px;
  border: none;
  background-color: var(--white);
}
.block:hover {
  background: var(--red);
}
.row {
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 17px;
  border: 2px solid black;
  padding: 10px;
}
@media (max-width: 768px) {
  .linkback {
    padding: 0 0 0 50px;
    font-size: 15px;
  }
  .col-1 p {
    width: 100%;
  }
  .col-3 ul {
    width: 100%;
  }
  .row {
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 17px;
    border: 1px solid black;
    padding: 10px;
  }
  .col-11 {
    margin-left: -0px;
  }
}
@media (max-width: 1024px) {
  .col-11 {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .large {
    width: 100%;
  }
  .col-11 {
    margin-left: 0px;
  }
  .col-11 p.desc {
    font-size: 11px;
  }
}
@media (max-width: 820px) {
  .product-row {
    display: flex;
    grid-template-columns: none;
    flex-direction: row;
    gap:100px;
    /* width: 95%; */
    padding: 50px 20px;
  }
}
.carttts {
  padding: 180px 0 0 0;
}
@media (max-width: 550px) {
  .carttts {
    padding: 120px 0 0 0;
  }
  .productscreen {
    padding: 150px 0 0 0;
  }
  .linkback {
    padding: 0 0 0 20px;
    font-size: 15px;
  }
  .product-row {
    display: flex;
    grid-template-columns: none;
    flex-direction: column;
    width: 100%;
  }
  .descr {
    margin:0px 20px;
  }
  .large {
    width: 100%;
  }
  .danger {
    color: red;
  }
  .col-11 {
    display: flex;
    text-align: center;
    margin-left: 0px;
  }
}
.alert {
  padding: 1rem;
  border: 0.1rem;
  border-radius: 0.05rem;
}
.col h1 {
  text-align: center;
  padding: 40px 0 20px;
}
.small {
  width: 50%;
}
.rowx {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 25px 0 45px;
  text-align: center;
  width: 50%;
  margin: auto;
  align-items: center;
  border-bottom: 1px solid black;
}
.card-body {
  width: 20%;
  margin: auto;
  padding: 55px 0 50px;
  text-align: center;
}
@media (max-width: 768px) {
  .small {
    width: 50%;
  }
  .rowx {
    width: 85%;
    padding: 25px 0 45px;
  }
  .card-body {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .col h1 {
    text-align: center;
    padding: 40px;
  }
  .small {
    width: 60%;
  }
  .rowx {
    grid-template-columns: repeat(2, 1fr);
    padding: 25px 0 35px;
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .sm {
    display: block;
  }
  .card-body {
    width: 50%;
    margin: auto;
    padding: 20px 0 50px;
  }
  .card-body h2 {
    font-size: 15px;
  }
  .card-body button .block {
    border-radius: 0px;
    background: chocolate;
  }
}
.card-body button .primary.block {
  border-radius: 0px;
  background: chocolate;
}

footer {
  background: transparent;
  height: auto;
  width: auto;
  padding-top: 40px;
  color: var(--gray);
}
.footer-content {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: rgba(0, 0, 0, 2);
}
.footer-content img {
  margin-top: 20px;
  width: 10%;
  opacity: 1;
}

.footer-content p {
  max-width: 500px;
  line-height: 28px;
  font-size: 14px;
  width: 80%;
  color: whitesmoke;
  padding: 20px 0;
}
.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
  cursor: pointer;
}
.socials a {
  overflow-x: hidden;
  font-size: 20px;
}
.socials a i {
  font-size: 20px;
  transition: color 0.5s ease;
  color: var(--white);
  margin: 0 15px;
}
.socials li:hover {
  color: var(--red);
}
.footer-bottom {
  background: black;
  width: 100%;
  padding: 20px 20px;
}
.footer-bottom p {
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .footer-content img {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .footer-content img {
    width: 30%;
  }
}
.cartempty {
  text-align: center;
  padding: 10px 0;
  height: fit-content;
  color: var(--white);
}
.emptyh1 {
  padding-bottom: 10px;
}
.buttonempty {
  padding: 10px 12px;
  border-radius: 20px;
  border: none;
  background-color: var(--white);
}
.buttonempty.empty {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 15px;
}
.cart {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
  text-align: center;
  align-items: center;
  color: var(--white);
}
.cartdesc {
  font-size: 17px;
  align-items: center;
}
.cart > * {
  flex: 100%;
}
.smalls {
  width: 50%;
}
.smallss {
  width: 75px;
}

.smallstwo {
  width: 50px;
}
.smallsstwo {
  width: 30px;
}
.cartfull {
  display: flex;
  justify-content: center;
}
button {
  cursor: pointer;
}
@media (max-width: 768px) {
  .smalls {
    width: 100%;
  }
  .cartdesc {
    font-size: 14px;
    align-items: center;
  }
}
@media (max-width: 600px) {
  .cart {
    width: 90%;
  }
}
form {
  width: 50%;
  margin: auto;
}
form h2 {
  color: var(--white);
  margin: 20px;
  font-size: 25px;
}
fieldset {
  margin-top: 50px;
  text-align: center;
}
input {
  padding: 14px;
  margin: 10px 0;
  width: 90%;
  font-size: 13px;
  background-color: var(--white);
  border: none;
  outline: none;
}
.inputs {
  position: relative;
}
#submitbtn {
  margin: 20px 0;
  padding: 15px 30px;
  width: 50%;
  border-radius: 20px;
  background-color: var(--red);
}
.paystack-button {
  margin: 0px 0 20px;
  padding: 15px 30px;
  width: 50%;
  border-radius: 20px;
  background-color: var(--red);
}

@media (max-width: 900px) {
  form {
    width: 70%;
  }
}
@media (max-width: 600px) {
  form {
    width: 90%;
  }

  #submitbtn {
    margin: 20px 0;
    padding: 15px 30px;
    width: 90%;
    border-radius: 20px;
    background-color: var(--red);
  }
}
.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(auto, 150px);
  padding-top: 70px;
}
.pic {
  width: 80%;
  margin: auto;
}
.pic:nth-child(1) {
  transform: translateY(-20px);
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}
.pic:nth-child(2) {
  grid-row: 3 / 6;
  grid-column: 1 / 2;
}
.pic:nth-child(3) {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}
.pic:nth-child(4) {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  z-index: 1;
}
.pic:nth-child(5) {
  grid-row: 4 / 7;
  grid-column: 2 / 3;
}
.pic:nth-child(6) {
  grid-row: 2 / 6;
  grid-column: 2 / 3;
}
.pic:nth-child(5) {
  grid-row: 2 / 7;
  grid-column: 3 / 5;
}
@media (max-width: 600px) {
  .gallery {
    display: block;
    width: 80%;
    margin: auto;
  }
  .pic {
    width: 100%;
    padding-bottom: 30px;
  }
  .pic:nth-child(7) {
    display: none;
  }
}
.address {
  display: flex;
  justify-content: space-around;
}
.payorder {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
}
@media (max-width: 600px) {
  .payorder {
    width: 90%;
  }
}
.cart.two {
  width: 90%;
}
.customer {
  padding-top: 100px;
}
.shipping {
  padding: 20px 0;
  width: 50%;
    background-color: rgba(0, 0, 0, 0.3);
  margin: 40px auto;
}
.shipping h1 {
  color: var(--white);
  margin: 20px;
  font-size: 25px;
}
.shipping-price {
  padding: 20px 0 0 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
  color: var(--red);
  text-align: left;
  width: 90%;
  margin: auto;
}
.shipping-price div {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .shipping {
    padding: 20px 0;
    width: 70%;
    margin: 40px auto;
  }
}
@media (max-width: 500px) {
  .shipping h1 {
    font-size: 19px;
  }
  .shipping {
    padding: 20px 0;
    width: 90%;
    margin: 40px auto;
  }
  .shipping-price {
    width: 90%;
    margin: auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
    color: var(--red);
    font-size: 12px;
  }
}

.loader {
  display: flex;
  justify-content: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s infinite;
}
.qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.decrement, .increment {
  width: 80px;
  height: 40px; 
  background-color: white; 
  color: black;
  border: 2px solid black;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  letter-spacing: 1px;
}

.qty-value {
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: 40px;
  text-align: center;
}

